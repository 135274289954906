import store from './store';
// import Vue from 'vue'
import {
  registerMicroApps,
  runAfterFirstMounted,
  start,
  setDefaultMountApp,
} from 'qiankun';
// import { getAppConfigsApi } from '@/api/user'
// import { chooseSpace } from '@/api/user.js'
import emits from './utils/emit';
// import { getToken } from './utils/auth'
/**
 * @name 启用qiankun微前端应用
 * @param {*} list
 * @param {*} defaultApp
 */
/**
 * @name 声明子应用挂载dom，如果不需要做keep-alive，则只需要一个dom即可；
 */

const useQianKun = list => {
  /**
   * @name 注册子应用
   * @param {Array} list subApps
   */

  registerMicroApps(list, {
    beforeLoad: [
      app => {
        // console.log('[LifeCycle] before load %c%s', 'color: green;', app.name);
      },
    ],
    beforeMount: [
      app => {
        // console.warn(app);
        // console.log('[LifeCycle] before mount %c%s', 'color: green;', app.name);
      },
    ],
    afterUnmount: [
      app => {
        app.props = {};
        // console.log(
        //   '[LifeCycle] after unmount %c%s',
        //   'color: green;',
        //   app.name
        // );
      },
    ],
  });

  /**
   * @name 设置默认进入的子应用
   * @param {String} 需要进入的子应用路由前缀
   */
  setDefaultMountApp(list[0].activeRule + '/');

  /**
   * @name 启动微前端
   */
  // start({
  //   sandbox: { strictStyleIsolation: true }
  // })
  start();
  /**
   * @name 微前端启动进入第一个子应用后回调函数
   */
  runAfterFirstMounted(() => {
    // console.log('[MainApp] first app mounted');
  });
};

export const registerApps = (apps, hideFrame) => {
  let appContainer = '';
  if (hideFrame) {
    appContainer = '#fullViewport';
  } else {
    appContainer = '#appViewport';
  }

  // const props = { token }
  const microApps = []; // 子应用数组盒子

  let entryMap = {
    nuwa: 'http://127.0.0.1:10081', //女娲
    phecda: 'http://127.0.0.1:10086', //核保
    'core-boss': 'http://127.0.0.1:10200', //统一后台
    'iop-boss': 'http://127.0.0.1:10300', //开放平台后管
  };

  // 如果调试测试环境，修改entry
  // console.log('=============================');
  // console.log(apps);
  // console.log(process.env.NODE_ENV);

  let newApps;
  // 如果开发环境调试测试环境接口
  if (
    process.env.NODE_ENV == 'development' &&
    process.env.VUE_APP_BASE_API == '//192.168.50.162:12000'
  ) {
    newApps = apps.map(item => {
      item.spaceAppEntry = entryMap[item.spaceAppCode] || item.spaceAppEntry;
      return item;
    });
  } else {
    newApps = apps;
  }

  // console.log(newApps);
  // console.log('=============================');

  apps.forEach(i => {
    microApps.push({
      name: i.spaceAppName, // 子应用名
      entry: i.spaceAppEntry, // 根据环境注册生产环境or开发环境地址
      container: appContainer, // 绑定dom
      activeRule: i.spaceAppActiveRule, // 绑定子应用路由前缀
      props: { emits }, // 将props及子应用路由，路由前缀由主应用下发
    });

    // microApps.push({
    //   name: 'iop-boss', // 子应用名
    //   entry: '//localhost:10300/', // 根据环境注册生产环境or开发环境地址
    //   container: appContainer, // 绑定dom
    //   activeRule: '/iop', // 绑定子应用路由前缀
    //   props: { emits } // 将props及子应用路由，路由前缀由主应用下发
    // })
  });
  // console.log(microApps);
  // 启用qiankun微前端应用

  useQianKun(microApps);
};
