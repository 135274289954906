const state = {
  sideOpen: true
}

const mutations = {
  TOGGLE_SIDEBAR_OPEN: state => {
    state.sideOpen = !state.sideOpen
  }
}

const actions = {
  toggleSidebarOpen({ commit }) {
    commit("TOGGLE_SIDEBAR_OPEN")
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
