<template>
  <div class="hamburger"
       @click="toggleClick">
    <img v-if="!isActive"
         src="@/assets/spread.svg">
    <img v-else
         src="@/assets/fold.svg"
         icon-class="fold">
  </div>
</template>

<script>
export default {
  name: "Hamburger",
  props: {
    isActive: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    toggleClick() {
      this.$emit("toggleClick")
    }
  }
}
</script>

<style lang="scss" scoped>
.hamburger {
  box-sizing: border-box;
  height: 100%;
  cursor: pointer;
  margin: 0px 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  &:hover {
    background-color: #f4f4f4;
  }
}

.hamburger.is-active {
  transform: rotate(180deg);
}
</style>
