<template>
  <div class="sidebar" :class="{ fold: !sideOpen }">
    <el-scrollbar style="height: 100%" wrap-class="scrollbar-wrapper">
      <el-menu
        :default-active="activeMenu"
        background-color="#304156"
        text-color="#fff"
        active-text-color="#3F9CFC"
        :collapse-transition="false"
        :popper-append-to-body="false"
        :collapse="!sideOpen"
        :router="true"
      >
        <template v-for="(item, index) in menus">
          <el-submenu
            v-if="item.childrenSpaceMenus && item.childrenSpaceMenus.length"
            :key="index"
            :index="'index' + index"
            popper-append-to-body
          >
            <template slot="title">
              <i class="icon" :class="item.spaceMenuIcon" />
              <span style="margin-left: 9px" slot="title">{{ item.spaceMenuName }}</span>
            </template>

            <el-menu-item
              v-for="(sub, i) in item.childrenSpaceMenus"
              :key="'sub' + i"
              :index="sub.spaceMenuEntry"
            >
              <i class="icon" :class="sub.spaceMenuIcon" />
              <span>{{ sub.spaceMenuName }}</span>
            </el-menu-item>
          </el-submenu>

          <div v-else :key="index">
            <el-menu-item :index="item.spaceMenuEntry">
              <i :class="item.spaceMenuIcon" />
              <span slot="title">{{ item.spaceMenuName }}</span>
            </el-menu-item>
          </div>
        </template>
      </el-menu>
    </el-scrollbar>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      isCollapse: false,
    };
  },
  computed: {
    ...mapGetters(["sideOpen", "menus"]),
    activeMenu() {
      const route = this.$route;

      const { fullPath } = route;

      // if set path, the sidebar will highlight the path you set
      return fullPath;
    },
  },
  created() {
    // console.log(this.$route)
  },

  methods: {
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
    // tabPage(item) {
    //   console.log(item)
    //   localStorage.setItem("cacheItem", JSON.stringify(item))
    //   window.history.pushState({}, item.spaceMenuName, item.spaceMenuEntry)
    //   // this.$router.push({ path: item.path })
    // }
  },
};
</script>

<style lang="scss" scoped>
.sidebar {
  position: fixed;
  width: 240px;
  top: 0px;
  background-color: #304156;
  transition: width 0.28s;
  height: 100vh;
  // padding-bottom: 10px;
  .el-menu {
    border-right: none;
  }
  .el-menu-item {
    a {
      display: block;
      color: #fff;
    }
  }
  ::v-deep .el-submenu__title {
    padding: 0 15px !important;
  }
  ::v-deep .el-menu--collapse {
    width: 54px;
  }

  ::v-deep .el-scrollbar__wrap {
    overflow-x: hidden;
  }
  .el-submenu {
    i.icon {
      vertical-align: middle;
      margin-right: 5px;
      width: 24px;
      text-align: center;
      font-size: 18px;
      font-style: inherit;
    }
  }
  &.fold {
    width: 54px;
  }
  .el-select {
    display: block;
    width: 220px;
    margin: 10px auto;
    ::v-deep {
      .el-input__inner {
        // background-color: rgba(0, 0, 0, 0.4);
        border-color: rgba(255, 255, 255, 0.2);
        background-color: #304156;
        color: #fff;
      }
    }
  }
  .dropdown-icon {
    width: 54px;
    text-align: center;
    color: #909399;
    line-height: 32px;
    padding-top: 10px;
  }
  .el-scrollbar {
    padding-bottom: 10px;
  }

  ::v-deep i.icon[class*='el-icon-']{
    margin: 0px 0px 0px 1px;
  }

  // 修改
  ::v-deep i.icon[class*='ion-'] {
    margin: 0px 4px 0px 5px;
  }
}
</style>
