import store from "../store"
import { removeToken } from "./auth"

/**
 * @name 退出登录
 */
// const haha = function() {
//   console.log("haha")
// }
// const emits = [{ name: "haha", fn: haha }]

const logout = {
  name: "logout",
  fn: function () {
    store.dispatch("user/logout").then(() => {
      localStorage.clear()
      window.history.replaceState({}, "登录", "/login")
      store.dispatch("tagsView/delAllVisitedViews")
      store.dispatch("tagsView/delAllCachedViews")
    })
  }
}

const setTags = {
  name: "setTags",
  fn: function (route) {
    store.dispatch("tagsView/addView", route)
  }
}
const setTab = {
  name: "setTab",
  fn: function (appCode, route, isHash) {
    const tag = '/' + appCode + (isHash ? '/#' : '')

    const newRoute = { ...route }
    newRoute.fullPath = tag + newRoute.fullPath
    newRoute.path = tag + newRoute.path

    store.dispatch("tagsView/addView", newRoute)
  }
}
const closeTag = {
  name: "closeTag",
  fn: function (route) {
    store.dispatch("tagsView/closeTag", route)
  }
}
const closeTab = {
  name: "closeTab",
  fn: function (appCode, route, isHash) {
    const tag = '/' + appCode + (isHash ? '/#' : '')
    let data = {}
    data.path = tag + route.path
    store.dispatch("tagsView/closeTag", data)
  }
}

const setBreadcrumb = {
  name: "setBreadcrumb",
  fn: function (matched) {
    store.dispatch("breadcrumb/setBreadcrumb", matched)
  }
}
const setNavbarBreadcrumb = {
  name: "setNavbarBreadcrumb",
  fn: function (appCode, route, isHash) {
    const tag = '/' + appCode + (isHash ? '/#' : '')
    let levelList = route.matched.map((item, i) => {
      item.path = tag + item.path
      if (i == route.matched.length - 1) {
        item.params = route.params
      }
      return item
    })
    store.dispatch("breadcrumb/setBreadcrumb", levelList)
  }
}
const setNavbarDom = {
  name: "setNavbarDom",
  fn: function (dom) {
    store.dispatch("user/setNavbarDom", dom)
  }
}

const getAppToken = {
  name: 'getAppToken',
  fn: function (appCode) {
    let app = store.state.user.appToken.find(item => {
      return item.spaceAppName == appCode
    })
    return app.spaceAppToken
  }
}
const getUserInfo = {
  name: 'getUserInfo',
  fn: function (appCode) {
    return store.state.user.userInfo
  }
}
const onTabClose = {
  name: 'onTabClose',
  fn: function (callback) {
    console.log('callback', callback);
    localStorage.setItem('callback', callback)
    // callback()
  }
}

export default [logout, setTags, setTab, closeTag, closeTab, setBreadcrumb, setNavbarBreadcrumb, setNavbarDom, getAppToken, getUserInfo, onTabClose]
