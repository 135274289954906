import request from "@/utils/request"

// 获取后台门户应用集 v1
export function applys (params) {
    return request({
        url: "/manage/app/page",
        method: "get",
        params
    })
}
// 获取后台门户应用 v1
export function A_apply (data) {
    return request({
        url: `/manage/app/${data.appId}`,
        method: "get",
        // data
    })
}
// 新增,编辑后台门户应用 v1
export function add_edit_apply (data) {
    return request({
        url: "/manage/app",
        method: "post",
        data
    })
}
//创建.应用资源
export function add_apply_source (data) {
    return request({
      url: `/manage/app/permission/${data.appId}`,
      method: "post",
      data
  })
}
//查询.应用资源
export function get_apply_source (data) {
  return request({
    url: `/manage/app/permission/${data.appId}`,
    method: "get",
    // data
})
}
//验证重复, true: 已存在重复的字段, false: 不存在
export function verify_duplicate (params) {
  return request({
    url: `/manage/app/duplicate`,
    method: "get",
    params
})
}
//删除.应用资源
export function delete_apply_source (data) {
  return request({
    url: `/manage/app/permission/${data.tenantPermissionId}`,
    method: "delete",
    // params
})
}
//查询资源已授权空间ID列表
export function get_grant_list (params) {
  return request({
    url: `/manage/app/permission/grant`,
    method: "get",
    params
})
}
//查询.应用资源.匹配
export function search_apply_math (params) {
  return request({
    url: `/manage/app/permission/match/${params.tenantPermissionId}`,
    method: "get",
    // params
})
}
//创建/编辑.应用资源.匹配
export function add_edit_appSource_match (data) {
  return request({
    url: `/manage/app/permission/match/${data.tenantPermissionId}`,
    method: "post",
    data
})
}
//删除.应用资源.匹配
export function remove_appSource_match (data) {
  return request({
    url: `/manage/app/permission/match/${data.tenantPermissionResourceMatchId}`,
    method: "delete",
    // data
})
}
//删除 应用
export function remove_apply (data) {
  return request({
    url: `/manage/app/${data.id}`,
    method: "delete",
})
}
//验证重复, true: 已存在重复的字段, false: 不存在
export function apply_ifrepeat (params) {
  return request({
    url: `/manage/app/duplicate`,
    method: "get",
    params
})
}
//应用资源验证重复
export function tenant_ifrepeat (params) {
  return request({
    url: `/manage/tenant/permission/duplicate`,
    method: "get",
    params
})
}
