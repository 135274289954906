import Vue from "vue"
import router from "./router"
import store from "./store"
import App from "./App.vue"
import "normalize.css/normalize.css"
import ElementUI from "element-ui"
import "element-ui/lib/theme-chalk/index.css"

import '@/assets/ionicons/css/ionicons.min.css'
import '@/assets/fileicons/colours.less';

import "@/styles/index.scss" // global css
import "@/styles/common.scss" // global css
import "@/styles/element-variables.scss"

import "@/directive"

Vue.use(ElementUI, {
    size: "medium" // set element-ui default size
})

/**
 * @name 提取vue示例化方法
 */
export function vueRender () {
    Vue.config.productionTip = false
    new Vue({
        router,
        store,
        render: h => h(App)
    }).$mount("#main-container")
}
