const getters = {
  name: state => state.user.name,
  avatar: state => state.user.avatar,
  menus: state => state.user.menus,
  spaces: state => state.user.spaces,
  hideFrame: state => state.user.hideFrame,
  navbarDom: state => state.user.navbarDom,
  visitedViews: state => state.tagsView.visitedViews,
  cachedViews: state => state.tagsView.cachedViews,
  sideOpen: state => state.app.sideOpen,
  levelList: state => state.breadcrumb.levelList,
  applyList:state =>state.apply.applyList
}
export default getters
