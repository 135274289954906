const state = {
  levelList: []
}

const mutations = {
  SET_BREADCRUMB: (state, levelList) => {
    state.levelList = levelList
  }
}

const actions = {
  setBreadcrumb({ commit }, matched) {
    commit("SET_BREADCRUMB", matched)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
