import axios from "axios"
import { MessageBox, Message } from "element-ui"
import { setToken, getToken } from "@/utils/auth"
import jsonBig from "json-bigint"
import store from "../store"

// create an axios instance
const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
    // withCredentials: true, // send cookies when cross-domain requests
    transformResponse: [
        data => {
            try {
                // 如果转换成功则返回转换的数据结果
                return jsonBig.parse(data)
            } catch (err) {
                // 如果转换失败，则包装为统一数据格式并返回
                return {
                    data
                }
            }
        }
    ],
    timeout: 1 * 60000 // request timeout
})

// request interceptor
service.interceptors.request.use(
    config => {
        // do something before request is sent

        const token = getToken()
    const appToken = localStorage.getItem('appToken')
    // console.log('appToken====',appToken )
    // console.log('token====',token)
        if (token) {
            // let each request carry token
            // ['X-Auth-Token'] is a custom headers key
            // please modify it according to the actual situation
            config.headers["X-Auth-Token"] = token
            if (appToken) {
                config.headers["X-App-Auth-Token"] = appToken
            }

        }
        return config
    },
    error => {

        // do something with request error
        return Promise.reject(error)
    }
)

// response interceptor
service.interceptors.response.use(
    /**
     * If you want to get http information such as headers or status
     * Please return  response => response
     */

    /**
     * Determine the request status by custom code
     * Here is just an example
     * You can also judge the status by HTTP Status Code
     */
    response => {
        const {
            headers: {
                "x-auth-token": token,
                "X-App-Auth-Token": appToken,
                "content-type": contentType,
                "content-disposition": fileName
            },
            data: res,
            status
        } = response

        if (token) {
            setToken(token)
        }

        if (appToken) {
            localStorage.setItem('appToken', appToken)
        }

        if (fileName && contentType !== "application/json") {
            return {
                data: res.data,
                fileName: decodeURIComponent(escape(fileName)),
                fileType: contentType
            }
        }
        if (contentType === "image/png") {
            return res.data
        }
        // if (contentType == 'application/octet-stream') {
        //   return res.data
        // }
        // if the custom code is not 20000, it is judged as an error.
    let flag = false
    if (response.status == 200 || response.status == '200') {  //兼容伟斌接口返回。。。。
      if ( res.code != undefined && res.data != undefined && res.message != undefined) {
        if (res.code == '0' ||res.code == 0) {
          flag = true
        } else { 
          flag = false
        }
      }
      else { 
        flag =  true
      }
    }
    // console.log(flag,'666666')
        if (res.code === 0 || res.code === "0" || flag) {
            return res
        }

        Message({
            message: res.message || "服务异常",
            type: "error",
            duration: 10 * 1000
        })

        if (res.code === 100010 || res.code === "100010") {
            // to re-login
            MessageBox.confirm(
                "您已经登出，您可以取消停留在此页面，或再次登录。",
                "确认注销", {
                    confirmButtonText: "重新登录",
                    cancelButtonText: "取消",
                    type: "warning"
                }
            ).then(() => {
                store.dispatch("user/resetToken").then(() => {
                    location.reload()
                })
            })
        }

        return Promise.reject(new Error(res.message || "Error"))
    },
    error => {
        Message({
            message: error.message,
            type: "error",
            duration: 10 * 1000
        })
        return Promise.reject(error)
    }
)

export default service