<template>
  <el-dropdown trigger="click" @command="handleCommand">
    <div class="el-dropdown-link" :class="{ 'name-hide': !nameShow }">
      <div class="avatar">
        <img
          :src="avatar ? avatar : avatarDefault"
          alt="AVATAR"
          draggable="false"
        />
      </div>
      <span v-if="nameShow">{{ name }}</span>
      <i v-if="nameShow" class="el-icon-caret-bottom el-icon--right" />
    </div>
    <el-dropdown-menu slot="dropdown" size="medium">
      <template v-if="spaces.length">
        <el-dropdown-item disabled>切换空间</el-dropdown-item>

        <el-dropdown-item
          :command="item.spaceId.toString()"
          v-for="item in spaces"
          :disabled="spaceId == item.spaceId"
          :key="item.spaceId.toString()"
          >{{ item.spaceName }}</el-dropdown-item
        >
      </template>

      <el-dropdown-item command="b" divided>退出系统</el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      spaceId: localStorage.getItem('spaceId'),
    }
  },
  props: {
    nameShow: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters(['avatar', 'name', 'spaces']),
    avatarDefault() {
      return require('@/assets/avatar.png')
    },
  },
  methods: {
    handleCommand(command) {
      // 退出登录
      if (command === 'b') {
        this.$store.dispatch('user/logout').then(() => {
          this.$message.success('退出成功')
          this.$store.dispatch('tagsView/delAllVisitedViews')
          this.$store.dispatch('tagsView/delAllCachedViews')
          localStorage.clear()
          window.history.replaceState({}, '登录', '/login')
        })
      } else {
        this.spaceId = command
        localStorage.setItem('spaceId', command)
        this.$store.dispatch('user/loginSpace', command).then((res) => {
          this.$router.push(res)
          this.$store.dispatch('tagsView/delAllVisitedViews')
          this.$store.dispatch('tagsView/delAllCachedViews')
          this.$store.dispatch('breadcrumb/setBreadcrumb', [])
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.el-dropdown {
  flex: 0 0 calc(34% - 70px);
  padding-right: 20px;
  box-sizing: border-box;
}
.el-dropdown-link {
  display: flex;
  align-items: center;
  float: right;
  span {
    margin: 0 5px 0 10px;
  }
  i {
    color: #bdc8d5;
  }
  cursor: pointer;
  &.name-hide .avatar {
    width: 60px;
    height: 60px;
  }
}
::v-deep {
  .el-dropdown-menu__item:first-of-type {
    color: #333;
    font-weight: bold;
    margin-bottom: 10px;
  }
}
.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  display: inline-block;
  img {
    width: 100%;
    user-select: none;
  }
}
</style>
