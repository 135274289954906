<template>
  <div class="login-success">
    <div class="success-box">
      <el-select v-model="spaceValue" placeholder="请选择进入空间">
        <el-option
          v-for="item in spaces"
          :key="item.spaceId.toString()"
          :label="item.spaceName"
          :value="item.spaceId.toString()"
        />
      </el-select>
      <el-button type="primary" :disabled="disabled" @click="chooseSpace"
        >登录空间</el-button
      >
    </div>
  </div>
</template>
<script>
// import { registerApps } from '@/appRegister'
import { mapGetters } from 'vuex'
export default {
  name: '',
  data() {
    return {
      spaceValue: '',
    }
  },
  computed: {
    ...mapGetters(['spaces']),
    disabled() {
      return !this.spaceValue ? true : false
    },
  },
  methods: {
    chooseSpace() {
      localStorage.setItem('spaceId', this.spaceValue)
      this.$store.dispatch('user/loginSpace', this.spaceValue).then((res) => {
        this.$router.push(res)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.login-success {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: #283443;
}
.success-box {
  width: 300px;
  ::v-deep {
    .el-button {
      margin-top: 40px;
      width: 100%;
    }
    .el-input__inner {
      background-color: #283443;
    }
    .el-select {
      // left: 54px;
      width: 100%;
    }
  }
}
</style>
