import {
    login,
    logout,
    weChatLogin,
    queryUserMenus,
    chooseSpace
} from "@/api/user"
import { getToken, removeToken } from "@/utils/auth"
import { registerApps } from "@/appRegister"

// import { getToken, setToken, removeToken } from '@/utils/auth'
const state = {
    token: getToken(),
    name: "",
    avatar: "",
    spaces: localStorage.getItem("spaces")
        ? JSON.parse(localStorage.getItem("spaces"))
        : [],
    menus: [],
    hideFrame: false,
    appToken: [],
    navbarDom: '',
    navbarDomShow: false,
    userInfo: null,
}

const mutations = {
    SET_TOKEN: (state, token) => {
        state.token = token
    },
    SET_NAME: (state, name) => {
        state.name = name
    },
    SET_AVATAR: (state, avatar) => {
        state.avatar = avatar
    },
    SET_SPACES: (state, spaces) => {
        state.spaces = spaces
    },
    SET_MENUS: (state, menus) => {
        state.menus = menus
    },
    SET_HIDEFRAME: (state, hideFrame) => {
        state.hideFrame = hideFrame
    },
    SET_APPTOKEN: (state, appToken) => {
        state.appToken = appToken
    },
    SET_NAVBARDOM: (state, navbarDom) => {
        state.navbarDom = navbarDom
    },
    SET_USERINFO: (state, userInfo) => {
        state.userInfo = userInfo
    }
}

const actions = {
    login ({ commit }, userInfo) {
        const { username, password, captchaKey, captcha } = userInfo
        return new Promise((resolve, reject) => {
            login({
                username: username.trim(),
                password: password,
                captchaKey: captchaKey,
                captcha: captcha
            })
                .then(response => {
                    const { data } = response
                    const { nickname, avatar } = data.user
                    const spaces = data.spaces

                    // return alert(spaces)

                    commit("SET_NAME", nickname)
                    commit("SET_AVATAR", avatar)
                    commit("SET_SPACES", spaces)
                    commit("SET_USERINFO", data.user)

                    localStorage.setItem("spaces", spaces ? JSON.stringify(spaces) : [])
                    // useQianKun(spaces)
                    resolve(spaces ? spaces : [])
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    weChatLogin ({ commit }, info) {
        const { code, state } = info
        return new Promise((resolve, reject) => {
            weChatLogin({ code, state })
                .then(response => {
                    const { data } = response
                    const { nickname, avatar } = data.user
                    const spaces = data.spaces

                    commit("SET_NAME", nickname)
                    commit("SET_AVATAR", avatar)
                    commit("SET_SPACES", spaces)
                    commit("SET_USERINFO", data.user)
                    resolve(spaces)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    setMenus ({ commit }, menus) {
        commit("SET_MENUS", menus)
    },
    getInfo ({ commit }) {
        return new Promise((resolve, reject) => {
            queryUserMenus()
                .then(response => {
                    const { data } = response
                    const { nickname, avatar } = data.user
                    const userSpaceMenus = data.userSpaceMenus

                    commit("SET_NAME", nickname)
                    commit("SET_AVATAR", avatar)
                    commit("SET_MENUS", userSpaceMenus)
                    commit("SET_USERINFO", data.user)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
  loginSpace ({ commit, dispatch }, id) {
        return new Promise((resolve, reject) => {
          chooseSpace({ spaceId: id }).then(res => {
            const { space, user } = res.data
            const { nickname, avatar } = user
            commit("SET_NAME", nickname)
            commit("SET_AVATAR", avatar)
            commit("SET_MENUS", space.spaceMenus)
            // dispatch('user/setMenus', space.spaceMenus)
            commit("SET_HIDEFRAME", space.spaceIsFrameSelfManaged)
            commit("SET_USERINFO", user)
            localStorage.setItem("spaceApps", JSON.stringify(space.spaceApps))
           
            localStorage.setItem(
              "permissions",
              JSON.stringify(space.spacePermissions)
            )
            
                let appToken = space.spaceApps.map(item => {
                    let newItem = {
                        spaceAppName: item.spaceAppName,
                        spaceAppToken: item.spaceAppToken,
                    }
                    return newItem
                })
                commit("SET_APPTOKEN", appToken)
              const path_ = space.spaceApps.length ? space.spaceApps[0].spaceAppActiveRule + "/" : "/"
              if (space.spaceApps.length) { 
                console.log('----注册子应用-----')
                registerApps(space.spaceApps, state.hideFrame)
              }
                resolve(path_)
            })
        })
    },

    logout ({ commit }) {
        return new Promise((resolve, reject) => {
            logout()
                .then(response => {
                    commit("SET_TOKEN", "")
                    commit("SET_MENUS", [])
                    removeToken()
                    resolve()
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    resetToken ({ commit }) {
        return new Promise(resolve => {
            commit("SET_TOKEN", "")

            removeToken()
            localStorage.removeItem("appToken")
            localStorage.removeItem("spaceId")
            resolve()
        })
    },
    setNavbarDom ({ commit }, dom) {
        commit("SET_NAVBARDOM", dom)
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
