import { applys } from '@/api/apply';
const state = {
  applyList: [],//应用
  applySoureList:[],//应用资源
}

const mutations = {
  
  SET_ALLLYS: (state, list) => { 
    state.applyList = list
  },
  SET_ALLLY_SOURCES: (state, list) => { 
    state.applySoureList =list
  }
}

const actions = {
  fetchApplyList ({ commit }, query) { 
    return new Promise((resolve, reject) => { 
      applys(query).then(res => { 
        let content = res.content
        commit('SET_ALLLYS', content);
        resolve(content);
      }).catch(err => {
        reject(err)
      });
    })
  },

  setApplySources ({ commit }, data) { 
    commit("SET_ALLLY_SOURCES", data)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}