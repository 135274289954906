import request from "@/utils/request"

// 登录
export function login(data) {
  return request({
    url: "/login-v1",
    method: "post",
    data
  })
}
// 登出
export function logout() {
  return request({
    url: "/logout-v1",
    method: "post",
    data: {}
  })
}
// 获取登录验证码
export function queryLoginCaptcha() {
  return request({
    url: "/query-login-captcha-v1",
    method: "post",
    data: {}
  })
}
export function chooseSpace(data) {
  return request({
    url: "/space/login-space-v1",
    method: "post",
    data
  })
}
// 授权企业微信登录
export function weChatLogin(data) {
  return request({
    url: "/oauth2-login/work-wechat-v1",
    method: "post",
    data
  })
}
// 获取后台门户用户和用户空间菜单
export function queryUserMenus() {
  return request({
    url: "/space/query-user-space-menus-v1",
    method: "post",
    headers: { "x-space-auth-token": localStorage.getItem("spaceToken") },
    data: {}
  })
}
