<template>
  <router-view v-if="$route.path.indexOf('/login') > -1" />

  <div v-else-if="hideFrame" class="full-wrapper">
    <div id="fullViewport" class="full"></div>
    <div class="person-info" v-dragable @click.stop>
      <div class="drag">
        <i class="el-icon-rank"></i>
      </div>
      <div @mousedown.stop>
        <Logout :nameShow="false"></Logout>
      </div>
    </div>
  </div>

  <Layout v-else>
    <keep-alive exclude="Login">
      <router-view v-if="$route.name" :key="key" />
    </keep-alive>
    <div v-show="$route.name == null" id="appViewport"></div>
  </Layout>
</template>

<script>
import { mapGetters } from 'vuex'
import Layout from '@/layout/index.vue'
import Logout from './components/logout'
import { getToken } from '@/utils/auth'
// import MainMenu from "@/components/menu/index.vue";

export default {
  components: { Layout, Logout },
  data() {
    return {
      showChooseBox: false,
      spaceId: localStorage.getItem('spaceId'),
    }
  },
  computed: {
    ...mapGetters(['hideFrame', 'spaces', 'avatar']),
    key() {
      return this.$route.path
    },
    // newSpaces() {
    //   return this.spaces.filter((item) => {
    //     return item.spaceId != this.spaceId
    //   })
    // },
    // avatarDefault() {
    //   return require('@/assets/avatar.png')
    // },
  },

  created() {
    setTimeout(() => {
      console.log(this.$route, 'created')
      this.init(this.$route)
    }, 20)
  },

  watch: {
    $route(val) {
      console.log(val)
      const spaceApps = JSON.parse(localStorage.getItem('spaceApps'))
      if (spaceApps && spaceApps.length == 0) {
        //非子应用
        this.$store.dispatch('breadcrumb/setBreadcrumb', val.matched)
        if (val.name && val.name != 'Redirect') {
          this.$store.dispatch('tagsView/addView', val)
        }
      }
    },
  },

  methods: {
    init(val) {
      const token = getToken()
      // token不存在
      if (!token) {
        console.log('不存在token')
        let name = val.name
        console.log(name)
        console.log(!name)
        // console.log(name.indexOf("Login"))
        // 判断是否是登录相关页面
        if (!name || name.indexOf('Login') < 0) {
          console.log('不是登录')
          //不是
          this.$router.push('/login') //跳转到登录
        }
      }
      // 判断是否是主系统本身页面   是
      if (!val.hash && val.fullPath != '/') {
        return
      }
      // 不是   注册微应用并启动微前端
      const id = localStorage.getItem('spaceId')
      if (id) {
        this.$store.dispatch('user/loginSpace', id)
      }
    },

    // showChoose() {
    //   this.showChooseBox = !this.showChooseBox
    //   this.spaceId = Number(localStorage.getItem('spaceId'))
    // },
    // changeSpace(val) {
    //   this.spaceId = val
    //   localStorage.setItem('spaceId', val)
    //   this.$store.dispatch('user/loginSpace', val).then((res) => {
    //     this.$router.push(res)
    //     this.showChooseBox = false
    //     this.$store.dispatch('tagsView/delAllVisitedViews')
    //     this.$store.dispatch('tagsView/delAllCachedViews')
    //     this.$store.dispatch('breadcrumb/setBreadcrumb', [])
    //   })
    // },
    // logout() {
    //   this.$store.dispatch('user/logout').then(() => {
    //     P('退出成功')
    //     this.$store.dispatch('tagsView/delAllVisitedViews')
    //     this.$store.dispatch('tagsView/delAllCachedViews')
    //     localStorage.clear()
    //     window.history.replaceState({}, '登录', '/login')
    //   })
    // },
  },
}
</script>

<style lang="scss" scoped>
.full-wrapper {
  position: relative;
}
.person-info {
  position: absolute;
  bottom: 100px;
  right: 50px;
  width: 60px;
  height: 100px;
  z-index: 999999;
  &:hover {
    .drag {
      visibility: initial;
    }
  }
  .drag {
    color: #666;
    height: 40px;
    line-height: 40px;
    text-align: center;
    font-size: 20px;
    cursor: pointer;
    visibility: hidden;
  }
}

#fullViewport {
  height: 100%;
  flex: auto;
}
#appViewport {
  background-color: #f1f1f5;
  overflow: hidden;
}
</style>
