import { initGlobalState, MicroAppStateActions } from "qiankun";


// const initialState = {
//   ...store.state.shared,
//   token: localStorage.token,
// };
const initialState = {}

// 初始化 state
const globalActions = initGlobalState(initialState);
globalActions.onGlobalStateChange((state, prev) => {
  console.log("主应用: 变更前");
  console.log(prev);
  console.log("主应用: 变更后");
  console.log(state);
});

export default globalActions;
