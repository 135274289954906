<template>
  <div id="portal" class="layout">
    <Sidebar />
    <div class="portal-container" :class="{ 'portal-fold': !sideOpen }">
      <header class="app-header">
        <Navbar />
        <TagsView />
      </header>
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Sidebar from "@/components/Sidebar/index.vue";
import Navbar from "@/components/Navbar/index.vue";
import TagsView from "@/components/TagsView/index.vue";

export default {
  components: { Sidebar, Navbar, TagsView },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["sideOpen"]),
  },
};
</script>

<style lang="scss" scoped>
#portal {
  height: 100vh;
  width: 100vw;
  overflow: hidden;

  .portal-container {
    position: absolute;
    top: 0px;
    left: 240px;
    bottom: 0px;
    right: 0px;
    height: 100%;
    width: calc(100vw - 240px);
    transition: all 0.28s;
    overflow: hidden;
    box-sizing: border-box;
    &.portal-fold {
      width: calc(100vw - 56px);
      left: 56px;
    }
  }
}

</style>
