import Vue from "vue"
import VueRouter from "vue-router"
import Login from '../views/Login.vue'
import LoginSuccess from '../views/LoginSuccess.vue'
import Layout from "@/layout/index.vue"

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

// const originalReplace = VueRouter.prototype.replace;
// VueRouter.prototype.replace = function replace(location) {
//   return originalReplace.call(this, location).catch(err => err);
// };


Vue.use(VueRouter)

const routes = [

    {
        path: '/redirect',
        component: { render: h => h("router-view") },
        hidden: true,
        children: [
            {
                path: '/redirect/:path(.*)',
                name: 'Redirect',
                component: () => import('../views/redirect/index.vue')
            }
        ]
    },
    // {
    //   path: "/home",
    //   name: "Home",
    //   component: { render: h => h("router-view") },
    //   meta: { title: "首页" }
    // },
    {
        path: "/login",
        name: "Login",
        component: Login,
        meta: { title: "登录", }
    },
    {
        path: "/login-debug",
        name: "LoginDebug",
        component: () => import("../views/LoginDebug.vue"),
        meta: { title: "微信登录", }
    },
    {
        path: "/login-success",
        name: "LoginSuccess",
        component: LoginSuccess,
        meta: { title: "选择空间", }
    },
    {
        path: "/about",
        name: "About",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ "../views/About.vue"),
        meta: { title: "帮助" }
  },
    // {
    //   path: "/dashboard",
    //   name: "Dashboard",
    //   component: () => import("@/views/dashboard.vue"),
    //   meta: { title: "" }
    // },

    {
        path: "/",
        component: { render: h => h("router-view") },
        meta: { title: "统一后台" },
        // redirect: "/user",
        children: [
            {
                path: "/user",
                name: "User",
                component: () => import("@/views/plantform/user/index.vue"),
                meta: { title: "用户管理",}
            },
            {
                path: "/space",
                name: "Space",
                component: () => import("@/views/plantform/space/index.vue"),
                meta: { title: "空间管理" ,},
                children: [
                 
                ],
          },
          {
            path: '/spaceMenus/:spaceId/:name',
            name: "SpaceMenu",
            component: () => import("@/views/plantform/space_menus/index.vue"),
            meta: {title:params => {return decodeURIComponent(params.name) + ' - 配置菜单'},}
          },
          {
            path: '/spaceRoles/:spaceId/:name',
            name: "SpaceRole",
            component: () => import("@/views/plantform/space_roles/index.vue"),
            meta: {title:params => {return decodeURIComponent(params.name)+ ' - 配置角色'},}
          },
          {
            path: '/spaceUsers/:spaceId/:name',
            name: "SpaceUser",
            component: () => import("@/views/plantform/space_users/index.vue"),
            meta: {title:params => {return decodeURIComponent(params.name)+ ' - 配置用户'},}
          },
            {
                path: "/apply",
                name: "Apply",
                component: () => import("@/views/plantform/apply/index.vue"),
                meta: { title: "应用管理", }
          },
          
        ]
    },
]
const router = new VueRouter({
    mode: "history",
    routes,
})

export default router
