<template>
  <div class="navbar">
    <div class="hamburger-container" :class="{ 'big-breadcrumb': !navbarDom }">
      <hamburger :is-active="sideOpen" @toggleClick="toggleSideBar" />
      <el-breadcrumb separator="/" >
        <el-breadcrumb-item v-for="(item, index) in levelList" :key="item.path">
          <span
            v-if="
              item.redirect === 'noRedirect' || index == levelList.length - 1
            "
            class="no-redirect"
            >{{ renderTitle(item.meta.title, item.params) }}</span
          >
          <a v-else @click.prevent="handleLink(item)">{{
            renderTitle(item.meta.title, item.params)
          }}</a>
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <div v-if="navbarDom" class="add-dom" v-html="navbarDom"></div>
    <Logout></Logout>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Hamburger from "../Hamburger";
import Logout from "../logout";
import ScrollPane from "../TagsView/ScrollPane.vue";
export default {
  name: "Navbar",
  components: { Hamburger, Logout, ScrollPane },

  data() {
    return {};
  },
  computed: {
    ...mapGetters(["sideOpen", "levelList", "navbarDom"]),
  },
  methods: {
    toggleSideBar() {
      this.$store.dispatch("app/toggleSidebarOpen");
    },
    handleLink(item) {
      console.log(item);
      this.$router.push(item.path);
    },
    renderTitle(title, params) {
      if (title && typeof title === "function") {
        let p = Object.keys(this.$route.params).length
          ? this.$route.params
          : params;
        return title(p);
      } else {
        return title;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 56px;
  background-color: #fff;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
}
.add-dom {
  flex: 0 0 33%;
  display: flex;
  justify-content: center;
}
.el-breadcrumb {
  // flex: auto;
  display: flex;
  overflow-x: auto;
  height: 40px;
  margin-top: 25px;
  ::v-deep .el-breadcrumb__item {
    white-space: nowrap;
  }
  &.big-breadcrumb {
    flex: 0 0 63%;
  }
}

.hamburger-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 0 0 33%
}
</style>
